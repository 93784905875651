<div class="row">
    <div class="col">
        <!-- Error -->
        <app-error [marginBottom]="true" [marginTop]="bordered ? true : false"></app-error>
    </div>
</div>

<!-- Table no GPUs -->
<app-table-list
    [tableName]="'broadcaster'"
    [ngClass]="{ 'mt-2': bordered }"
    [displayTableName]="'BROADCASTERS' | translate"
    [data]="allBroadcastersObservable | async"
    [(tableSchema)]="tableColumnsSchema"
    (rowSelected)="selectRow($event)"
    [showFilter]="true"
    [showPagination]="true"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [autoRows]="autoRows"
    [refreshing]="loading"
    *ngIf="!clusterHasGPUs()"
>
    <!-- Add Broadcaster Buttons  -->
    <div class="form-group mb-0">
        <button type="button" class="btn btn-outline-primary" (click)="addBroadcaster()" [disabled]="!canEdit" title="{{ 'ADD_BROADCASTER' | translate }}">
            <fa-icon icon="plus" size="sm"></fa-icon>
            {{ "ADD_BROADCASTER" | translate }}
        </button>
    </div>
</app-table-list>

<!-- Table w/ GPUs -->
<app-table-list
    [tableName]="'broadcaster_gpu'"
    [ngClass]="{ 'mt-2': bordered }"
    [displayTableName]="'BROADCASTERS' | translate"
    [data]="allBroadcastersObservable | async"
    [(tableSchema)]="tableColumnsSchemaGPUs"
    (rowSelected)="selectRow($event)"
    [showFilter]="true"
    [showPagination]="true"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [autoRows]="autoRows"
    [refreshing]="loading"
    *ngIf="clusterHasGPUs()"
>
    <!-- Add Broadcaster Buttons  -->
    <div class="form-group mb-0">
        <button type="button" class="btn btn-outline-primary" (click)="addBroadcaster()" [disabled]="!canEdit" title="{{ 'ADD_BROADCASTER' | translate }}">
            <fa-icon icon="plus" size="sm"></fa-icon>
            {{ "ADD_BROADCASTER" | translate }}
        </button>
    </div>
</app-table-list>
