<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_BROADCASTER" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_BROADCASTER" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <div class="form-group">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="broadcaster.name"
                                [required]="!cluster._frontData.is_auto_scaling || isEdit"
                                pattern="^([a-zA-Z][a-zA-Z0-9_\-]*)$"
                                minlength="{{ minLength }}"
                                duplicateName="{{ broadcasterNames }}"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.pattern">
                                    {{ "INVALID_NAME_NAME_SHOULD_START_WITH_A_LETTER_AND_CAN_CONTAIN_LETTERS_NUMBERS_UNDERSCORES_OR_DASHES_ONLY" | translate }}.
                                </div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Location -->
                        <div class="form-group">
                            <label for="location_text"
                                >{{ "LOCATION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #LocationContent>{{ "TOOLTIP.LOCATION" | translate }}</ng-template></label
                            >
                            <zx-location-search [(model)]="broadcaster.location.address"></zx-location-search>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- API User and Password -->
                        <fieldset *ngIf="!cluster._frontData.is_auto_scaling">
                            <div class="row">
                                <div class="col-12 col-sm-6 mb-3">
                                    <label for="api_user" [ngClass]="{ 'is-invalid': form.submitted && api_user.errors }"
                                        >{{ "API_USER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                        ><fa-icon
                                            *ngIf="isEdit"
                                            icon="redo"
                                            size="sm"
                                            [ngbTooltip]="RestartContent"
                                            triggers="hover click"
                                            [closeDelay]="500"
                                        ></fa-icon>
                                        <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                                    >
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            id="api_user"
                                            name="api_user"
                                            placeholder="{{ 'API_USER' | translate }}"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': form.submitted && api_user.errors }"
                                            [(ngModel)]="broadcaster.api_user"
                                            required
                                            #api_user="ngModel"
                                        />
                                        <button
                                            class="btn btn-outline-primary"
                                            (click)="copyPassword(broadcaster.api_user)"
                                            type="button"
                                            title="{{ 'COPY' | translate }}"
                                        >
                                            <fa-icon icon="clipboard"></fa-icon>
                                        </button>
                                    </div>
                                    <div *ngIf="api_user.invalid" class="invalid-feedback">
                                        <div *ngIf="api_user.errors.required">{{ "API_USER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 mb-3">
                                    <app-password-input
                                        [(passwordInput)]="broadcaster.api_password"
                                        [isFormSubmitted]="form.submitted"
                                        [isEdit]="isEdit"
                                    ></app-password-input>
                                </div>
                            </div>
                        </fieldset>

                        <!-- SSH Key -->
                        <div *ngIf="!cluster._frontData.is_auto_scaling" class="form-group">
                            <label for="remote_access_key_id" [ngClass]="{ 'is-invalid': form.submitted && key.errors }">
                                {{ "SSH_KEY" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="SSHContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #SSHContent>{{ "TOOLTIP.SSH_KEY" | translate }} > {{ "SSH_KEYS" | translate }}</ng-template
                                ><fa-icon
                                    *ngIf="isEdit"
                                    icon="redo"
                                    size="sm"
                                    [ngbTooltip]="RestartContent"
                                    triggers="hover click"
                                    [closeDelay]="500"
                                ></fa-icon>
                            </label>
                            <ng-select
                                id="remote_access_key_id"
                                name="remote_access_key_id"
                                required
                                [items]="accessKeys"
                                [clearable]="false"
                                bindLabel="name"
                                placeholder="{{ 'SELECT' | translate }} {{ 'SSH_KEY' | translate }}"
                                [(ngModel)]="broadcaster.accessKey"
                                #key="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && key.errors }"
                            >
                            </ng-select>
                            <div *ngIf="key.invalid" class="invalid-feedback">
                                <div *ngIf="key.errors.required">{{ "SSH_KEY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Type -->
                        <fieldset class="form-group">
                            <div class="w-100 mb-2">
                                <legend for="type">
                                    {{ "TYPE" | translate }}
                                    <fa-icon icon="info-circle" [ngbTooltip]="TypeContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                    <ng-template #TypeContent>
                                        <p>{{ "TOOLTIP.BROADCASTER_TYPE_PRIMARY" | translate }}</p>
                                        <p class="mb-0">{{ "TOOLTIP.BROADCASTER_TYPE_BACKUP" | translate }}</p>
                                    </ng-template>
                                </legend>
                                <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="broadcaster.is_backup">
                                    <mat-button-toggle [value]="0" [disabled]="cluster._frontData.is_auto_scaling && isEdit">{{
                                        "PRIMARY" | translate
                                    }}</mat-button-toggle>
                                    <mat-button-toggle [value]="1" [disabled]="cluster._frontData.is_auto_scaling && isEdit">{{
                                        "BACKUP" | translate
                                    }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <small>{{
                                "BACKUP_BROADCASTERS_WILL_ONLY_RECEIVE_SOURCES_IF_MANUALLY_CONFIGURED_OR_IF_ALL_PRIMARY_BROADCASTERS_ARE_UNAVAILABLE"
                                    | translate
                            }}</small>
                        </fieldset>
                    </section>
                    <!-- Advanced -->
                    <div>
                        <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
                            <h3
                                class="toggle-fieldset"
                                (click)="showAdvanced = !showAdvanced"
                                [ngClass]="[
                                    !showAdvanced ? 'closed' : '',
                                    form.submitted &&
                                    (form.controls.streaming_ip?.errors ||
                                        form.controls.private_ip?.errors ||
                                        form.controls.input_bw_limit?.errors ||
                                        form.controls.output_bw_limit?.errors)
                                        ? 'is-invalid'
                                        : ''
                                ]"
                            >
                                {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"></fa-icon
                                ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
                            </h3>
                        </div>
                        <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
                            <!-- Streaming IP -->
                            <div class="form-group">
                                <label for="streaming_ip" class="mb-0"
                                    >{{ "PUBLIC_IP" | translate
                                    }}<fa-icon
                                        *ngIf="isEdit"
                                        icon="redo"
                                        size="sm"
                                        [ngbTooltip]="RestartContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon>
                                </label>
                                <div class="input-group align-items-center">
                                    <div class="input-group-prepend">
                                        <div class="input-group">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="is_auto_detect"
                                                    name="is_auto_detect"
                                                    [(ngModel)]="isAutoDetect"
                                                />
                                                <label class="form-check-label" for="is_auto_detect">{{ "AUTO_DETECT" | translate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-div">
                                        <input
                                            type="text"
                                            id="streaming_ip"
                                            name="streaming_ip"
                                            placeholder="{{ 'IP' | translate }}"
                                            class="form-control"
                                            [(ngModel)]="broadcaster.streaming_ip"
                                            pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                            #streaming_ip="ngModel"
                                            required
                                            [ngClass]="{ 'is-invalid': form.submitted && streaming_ip.errors }"
                                            [disabled]="isAutoDetect"
                                        />
                                        <div *ngIf="streaming_ip.invalid" class="invalid-feedback">
                                            <div *ngIf="streaming_ip.errors.required">{{ "PUBLIC_IP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                            <div *ngIf="streaming_ip.errors.pattern">{{ "INVALID_IPV4_ADDRESS" | translate }}.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Private IP -->
                            <div class="form-group">
                                <label for="private_ip" class="mb-0">{{ "PRIVATE_IP" | translate }}</label>
                                <div class="input-group align-items-center">
                                    <div class="input-group-prepend">
                                        <div class="input-group">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="no_private_ip"
                                                    name="no_private_ip"
                                                    [(ngModel)]="noPrivateIp"
                                                />
                                                <label class="form-check-label" for="no_private_ip">{{ "USE_PUBLIC_IP" | translate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-div">
                                        <input
                                            type="text"
                                            id="private_ip"
                                            name="private_ip"
                                            placeholder="{{ 'IP' | translate }}"
                                            class="form-control"
                                            [(ngModel)]="broadcaster.private_ip"
                                            pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                            #private_ip="ngModel"
                                            required
                                            [ngClass]="{ 'is-invalid': form.submitted && private_ip.errors }"
                                            [disabled]="noPrivateIp"
                                        />
                                        <div *ngIf="private_ip.invalid" class="invalid-feedback">
                                            <div *ngIf="private_ip.errors.required">{{ "PRIVATE_IP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                            <div *ngIf="private_ip.errors.pattern">{{ "INVALID_IPV4_ADDRESS" | translate }}.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Bandwidth Limits -->
                            <fieldset class="form-group bordered">
                                <legend>{{ "BANDWIDTH_LIMITS" | translate }} [Mbps]</legend>
                                <div class="row">
                                    <div class="col">
                                        <label for="input_bw_limit" [ngClass]="{ 'is-invalid': form.submitted && input_bw_limit.errors }">{{
                                            "INPUT" | translate
                                        }}</label>
                                        <input
                                            type="number"
                                            id="input_bw_limit"
                                            name="input_bw_limit"
                                            placeholder="{{ 'INPUT' | translate }}"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': form.submitted && input_bw_limit.errors }"
                                            [(ngModel)]="broadcaster.input_bw_limit"
                                            min="0"
                                            pattern="^\d+$"
                                            #input_bw_limit="ngModel"
                                        />
                                        <div *ngIf="input_bw_limit.invalid" class="invalid-feedback">
                                            <div *ngIf="input_bw_limit.errors.pattern">
                                                {{ "INPUT_LIMIT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label for="output_bw_limit" [ngClass]="{ 'is-invalid': form.submitted && output_bw_limit.errors }">{{
                                            "OUTPUT" | translate
                                        }}</label>
                                        <input
                                            type="number"
                                            id="output_bw_limit"
                                            name="output_bw_limit"
                                            placeholder="{{ 'OUTPUT' | translate }}"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': form.submitted && output_bw_limit.errors }"
                                            [(ngModel)]="broadcaster.output_bw_limit"
                                            min="0"
                                            pattern="^\d+$"
                                            #output_bw_limit="ngModel"
                                        />
                                        <div *ngIf="output_bw_limit.invalid" class="invalid-feedback">
                                            <div *ngIf="output_bw_limit.errors.pattern">
                                                {{ "OUTPUT_LIMIT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </fieldset>
                    </div>

                    <!-- No More Elastic IPs Available Warning -->
                    <div *ngIf="!isEdit && !hasRemainingIps && this.cluster?.aws_account_id">
                        <fa-icon
                            icon="exclamation-circle"
                            class="me-1"
                            [ngClass]="{ 'status-warning': !cluster.require_eip, 'status-error': cluster.require_eip }"
                        ></fa-icon>
                        <small>{{ "TOOLTIP.NO_MORE_ELASTIC_IP" | translate }}</small>
                    </div>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <div class="text-end">
                        <!--<button type="button" class="btn btn-outline-danger me-2" (click)="deleteBroadcaster()" *ngIf="isEdit" [disabled]="deleting">
                            <fa-icon icon="trash-alt" size="sm"></fa-icon>
                            {{ "DELETE" | translate }}
                        </button>-->
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving || (!isEdit && !hasRemainingIps && cluster.require_eip)"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
