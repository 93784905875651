import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, ComponentRef } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import _ from "lodash";

import { Constants } from "../../../../constants/constants";
import { SharedService } from "../../../../services/shared.service";
import { UsersService } from "../../../account-management/users/users.service";
import { BroadcastersService } from "../../../../components/broadcasters/broadcasters.service";
import { Cluster } from "../../cluster";
import { Tag, Broadcaster, UserPermissions, KeyMap } from "../../../../models/shared";
import { TranslateService } from "@ngx-translate/core";
import { ErrorService } from "src/app/components/error/error.service";
//
import { urlBuilder } from "@zixi/shared-utils";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZxBroadcasterStatusColComponent } from "./broadcaster-status-col/broadcaster-status-col.component";
import { ZxBroadcasterNameColComponent } from "./broadcaster-name-col/broadcaster-name-col.component";
import { ZxBroadcasterIPColComponent } from "./broadcaster-ip-col/broadcaster-ip-col.component";
import { ZxBroadcasterStreamsColComponent } from "./broadcaster-streams-col/broadcaster-streams-col.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { DecimalPipe } from "@angular/common";
import { ZxBroadcasterActionsColComponent } from "./broadcaster-actions-col/broadcaster-actions-col.component";
import { LocationDisplayNamePipe } from "src/app/pipes/location-display-name.pipe";
import { NetworkPipe } from "src/app/pipes/network.pipe";
import { VersionPipe } from "src/app/pipes/version.pipe";

@Component({
    selector: "app-cluster-broadcasters",
    templateUrl: "./cluster-broadcasters.component.html"
})
export class ClusterBroadcastersComponent implements OnInit, OnDestroy, OnChanges {
    @Input() cluster: Cluster;
    @Input() resourceTags: Tag[];
    @Input() canEdit: boolean;
    @Input() bordered?: boolean;
    @Input() autoRows? = true;

    userPermissions: UserPermissions;

    adding: boolean;
    addingBackup: boolean;
    addingPrimary: boolean;
    loading = true;
    broadcasters: Broadcaster[] = [];
    urls = Constants.urls;

    tableExpanded = true;

    private broadcastersSubscription: Subscription;
    private broadcastersBS$ = new BehaviorSubject<Broadcaster[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<Broadcaster>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            sticky: 1,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row.name,
            component: ZxBroadcasterNameColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterNameColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxBroadcasterStatusColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStatusColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            },
            sortBy: (row: KeyMap<Broadcaster>) => this.translate.instant(this.stp.transform(row))
        },
        {
            header: this.translate.instant("CPU"),
            columnDef: "cpu",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.cpu, "1.0-2") ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm;
            },
            sortBy: row => row.status?.cpu ?? "-",
            bgValue: row => row.status?.cpu ?? null
        },
        {
            header: this.translate.instant("RAM"),
            columnDef: "ram",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.ram, "1.0-2") ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm;
            },
            sortBy: row => row.status?.ram ?? "-",
            bgValue: row => row.status?.ram ?? null
        },
        {
            header: this.translate.instant("HDD"),
            columnDef: "hdd",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number =
                    this.decimalPipe.transform(
                        Math.max(row.status?.disk_space, row.status?.disk_space_install),
                        "1.0-2"
                    ) ?? "-";
                compRef.unit = "%";
                compRef.searchTerm = searchTerm;
            },
            sortBy: row => Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? "-",
            bgValue: row => Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? null
        },
        {
            header: this.translate.instant("IN_BITRATE"),
            columnDef: "in_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.input_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.input_kbps
        },
        {
            header: this.translate.instant("OUT_BITRATE"),
            columnDef: "out_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.output_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.output_kbps
        },
        {
            header: this.translate.instant("STREAMS"),
            columnDef: "streams",
            width: 120,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row._frontData?.streams,
            component: ZxBroadcasterStreamsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStreamsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("UPTIME"),
            columnDef: "uptime",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => (row.status?.up_time_seconds ? this.uptime.transform(row.status?.up_time_seconds) : "-"),
                row => (row.status?.up_time_seconds ? this.uptime.transform(row.status?.up_time_seconds) : "-"),
                row => !!row?.status?.up_time_seconds
            ),
            sortBy: row => row.status?.up_time_seconds
        },
        {
            header: this.translate.instant("PRIMARY"),
            columnDef: "primary",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => (row.is_backup ? "No" : "Yes"),
                row => (row.is_backup ? "No" : "Yes"),
                () => true
            ),
            sortBy: row => (row.is_backup ? "No" : "Yes")
        },
        {
            header: this.translate.instant("PUBLIC_IP"),
            columnDef: "public_ip",
            width: 140,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.ip,
            component: ZxBroadcasterIPColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterIPColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    ip: row.status?.ip?.toString() ?? "",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("PRIVATE_IP"),
            columnDef: "private_ip",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.private_ip,
            component: ZxBroadcasterIPColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterIPColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string | string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    ip:
                        row?.status?.private_ip && row.status.private_ip !== row.status.ip
                            ? row.status?.private_ip?.toString()
                            : "",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("VERSION"),
            columnDef: "version",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row => !!row?.status?.about?.version
            ),
            sortBy: row => this.versionPipe.transform(row.status?.about?.version || "-")
        },
        {
            header: this.translate.instant("AGENTZ"),
            columnDef: "agentz",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.agentZStatus(row),
                row => this.agentZStatus(row),
                () => true
            ),
            sortBy: row => this.agentZStatus(row)
        },
        {
            header: this.translate.instant("HOST_ID"),
            columnDef: "host_id",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.status?.hostid ?? "-",
                row => row.status?.hostid ?? "-",
                () => true
            ),
            sortBy: row => row.status?.hostid ?? "-"
        },
        {
            header: this.translate.instant("INSTANCE_TYPE"),
            columnDef: "instance_type",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.instance_type,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.instance_type,
                row => (row.instance_type ? row.instance_type?.toString() : "-"),
                () => true
            )
        },
        {
            header: this.translate.instant("LOCATION"),
            columnDef: "location",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.locationPipe.transform(row.location),
                row => this.locationPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.locationPipe.transform(row.location) ?? "-"
        },
        {
            header: this.translate.instant("NETWORK"),
            columnDef: "network",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.networkPipe.transform(row.location),
                row => this.networkPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.networkPipe.transform(row.location) ?? "-"
        },
        {
            header: "",
            columnDef: "actions",
            width: 50,
            visible: true,
            hideFromColumnChooser: true,
            align: "right",
            stickyToLast: true,
            component: ZxBroadcasterActionsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterActionsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                broadcasterCompRef.broadcaster = row;
                broadcasterCompRef.cluster = this.cluster;
                broadcasterCompRef.resourceTags = this.resourceTags;
                broadcasterCompRef.userPermissions = this.userPermissions;
            }
        }
    ];

    tableColumnsSchemaGPUs: TableSchema<KeyMap<Broadcaster>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            sticky: 1,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row.name,
            component: ZxBroadcasterNameColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterNameColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxBroadcasterStatusColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStatusColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            },
            sortBy: (row: KeyMap<Broadcaster>) => this.translate.instant(this.stp.transform(row))
        },
        {
            header: this.translate.instant("CPU"),
            columnDef: "cpu",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.cpu, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => row.status?.cpu ?? "-",
            bgValue: row => row.status?.cpu ?? null
        },
        {
            header: this.translate.instant("RAM"),
            columnDef: "ram",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.status?.ram, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => row.status?.ram ?? "-",
            bgValue: row => row.status?.ram ?? null
        },
        {
            header: this.translate.instant("HDD"),
            columnDef: "hdd",
            width: 70,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number =
                    this.decimalPipe.transform(
                        Math.max(row.status?.disk_space, row.status?.disk_space_install),
                        "1.0-2"
                    ) ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? "-",
            bgValue: row => Math.max(row.status?.disk_space, row.status?.disk_space_install) ?? null
        },
        {
            header: this.translate.instant("GPU"),
            columnDef: "gpu",
            width: 70,
            align: "right",
            visible: true,
            hideFromColumnChooser: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => row.recentLoad?.nvidia_utilization ?? "-",
            bgValue: row => row.recentLoad?.nvidia_utilization ?? null
        },
        {
            header: this.translate.instant("GPU_MEM"),
            columnDef: "gpu_mem",
            width: 80,
            align: "right",
            visible: true,
            hideFromColumnChooser: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_mem_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => row.recentLoad?.nvidia_mem_utilization ?? "-",
            bgValue: row => row.recentLoad?.nvidia_mem_utilization ?? null
        },
        {
            header: this.translate.instant("GPU_ENC"),
            columnDef: "gpu_enc",
            width: 80,
            align: "right",
            visible: true,
            hideFromColumnChooser: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_encoder_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => row.recentLoad?.nvidia_encoder_utilization ?? "-",
            bgValue: row => row.recentLoad?.nvidia_encoder_utilization ?? null
        },
        {
            header: this.translate.instant("GPU_DEC"),
            columnDef: "gpu_dec",
            width: 80,
            align: "right",
            visible: true,
            hideFromColumnChooser: false,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const compRef = bitrateComponentRef.instance;
                compRef.number = this.decimalPipe.transform(row.recentLoad?.nvidia_decoder_utilization, "1.0-2") ?? "-";
                compRef.unit = "%";
            },
            sortBy: row => row.recentLoad?.nvidia_decoder_utilization ?? "-",
            bgValue: row => row.recentLoad?.nvidia_decoder_utilization ?? null
        },
        {
            header: this.translate.instant("IN_BITRATE"),
            columnDef: "in_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.input_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.input_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.input_kbps
        },
        {
            header: this.translate.instant("OUT_BITRATE"),
            columnDef: "out_bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Broadcaster>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.output_kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<Broadcaster>) => {
                const title = this.decimalPipe.transform(row.status?.output_kbps, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.output_kbps
        },
        {
            header: this.translate.instant("STREAMS"),
            columnDef: "streams",
            width: 120,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row._frontData?.streams,
            component: ZxBroadcasterStreamsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterStreamsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("UPTIME"),
            columnDef: "uptime",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => (row.status?.up_time_seconds ? this.uptime.transform(row.status?.up_time_seconds) : "-"),
                row => (row.status?.up_time_seconds ? this.uptime.transform(row.status?.up_time_seconds) : "-"),
                row => !!row?.status?.up_time_seconds
            ),
            sortBy: row => row.status?.up_time_seconds
        },
        {
            header: this.translate.instant("PRIMARY"),
            columnDef: "primary",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => (row.is_backup ? "No" : "Yes"),
                row => (row.is_backup ? "No" : "Yes"),
                () => true
            ),
            sortBy: row => (row.is_backup ? "No" : "Yes")
        },
        {
            header: this.translate.instant("PUBLIC_IP"),
            columnDef: "public_ip",
            width: 140,
            visible: true,
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.ip,
            component: ZxBroadcasterIPColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterIPColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    ip: row.status?.ip?.toString() ?? ""
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("PRIVATE_IP"),
            columnDef: "private_ip",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.status?.private_ip,
            component: ZxBroadcasterIPColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterIPColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    broadcaster: row,
                    ip:
                        row?.status?.private_ip && row.status.private_ip !== row.status.ip
                            ? row.status?.private_ip?.toString()
                            : ""
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("VERSION"),
            columnDef: "version",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row =>
                    this.versionPipe.transform(row.status?.about?.version || "-") +
                    (row.can_transcode ? " Transcoder" : ""),
                row => !!row?.status?.about?.version
            ),
            sortBy: row => this.versionPipe.transform(row.status?.about?.version || "-")
        },
        {
            header: this.translate.instant("AGENTZ"),
            columnDef: "agentz",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.agentZStatus(row),
                row => this.agentZStatus(row),
                () => true
            ),
            sortBy: row => this.agentZStatus(row)
        },
        {
            header: this.translate.instant("HOST_ID"),
            columnDef: "host_id",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.status?.hostid ?? "-",
                row => row.status?.hostid ?? "-",
                () => true
            ),
            sortBy: row => row.status?.hostid ?? "-"
        },
        {
            header: this.translate.instant("INSTANCE_TYPE"),
            columnDef: "instance_type",
            width: 120,
            visible: false,
            sortBy: (row: KeyMap<Broadcaster>) => row.instance_type,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => row.instance_type,
                row => (row.instance_type ? row.instance_type?.toString() : "-"),
                () => true
            )
        },
        {
            header: this.translate.instant("LOCATION"),
            columnDef: "location",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.locationPipe.transform(row.location),
                row => this.locationPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.locationPipe.transform(row.location) ?? "-"
        },
        {
            header: this.translate.instant("NETWORK"),
            columnDef: "network",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Broadcaster>>(
                row => this.networkPipe.transform(row.location),
                row => this.networkPipe.transform(row.location),
                () => true
            ),
            sortBy: (row: KeyMap<Broadcaster>) => this.networkPipe.transform(row.location) ?? "-"
        },
        {
            header: "",
            columnDef: "actions",
            width: 50,
            visible: true,
            hideFromColumnChooser: true,
            align: "right",
            stickyToLast: true,
            component: ZxBroadcasterActionsColComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterActionsColComponent>,
                row: KeyMap<Broadcaster>
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                broadcasterCompRef.broadcaster = row;
                broadcasterCompRef.cluster = this.cluster;
                broadcasterCompRef.resourceTags = this.resourceTags;
                broadcasterCompRef.userPermissions = this.userPermissions;
            }
        }
    ];

    constructor(
        private router: Router,
        public sharedService: SharedService,
        private bs: BroadcastersService,
        private userService: UsersService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private stp: StatusTextPipe,
        private uptime: UptimePipe,
        private locationPipe: LocationDisplayNamePipe,
        private networkPipe: NetworkPipe,
        private decimalPipe: DecimalPipe,
        private versionPipe: VersionPipe
    ) {}

    ngOnInit() {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // Broadcaster
        this.broadcastersSubscription = this.bs.broadcasters.subscribe(broadcasters => {
            this.broadcasters = broadcasters.filter(b => b.broadcaster_cluster_id === this.cluster.id);
            // console.log("this.broadcasters", this.broadcasters);
            this.prepTableData();
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.broadcastersSubscription.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.cluster) {
            if (
                !changes.cluster.previousValue ||
                !changes.cluster.currentValue ||
                changes.cluster.previousValue.id !== changes.cluster.currentValue.id
            ) {
                this.errorService.setLastError(null);
                this.broadcastersBS$.next([]);
                this.loading = true;
            }
        }
    }

    private prepTableData() {
        if (this.broadcasters) {
            this.broadcasters = [...this.broadcasters];
            this.broadcastersBS$.next(this.broadcasters);
        }
    }

    get allBroadcastersObservable() {
        return this.broadcastersBS$;
    }

    selectRow = (broadcaster: Broadcaster) => {
        this.router.navigate(urlBuilder.getRegularBroadcasterUrl(this.cluster.id, broadcaster.id, broadcaster.name));
    };

    clusterHasGPUs() {
        if (!this.cluster) return false;
        if (!this.broadcasters) return false;

        return _.reduce(
            this.broadcasters,
            (hasGPUs, bx) => {
                return (
                    hasGPUs ||
                    (bx.recentLoad && (bx.recentLoad.nvidia_utilization === 0 || bx.recentLoad.nvidia_utilization > 0))
                );
            },
            false
        );
    }

    addBroadcaster() {
        this.router.navigate(urlBuilder.getBroadcasterAddActionUrl(this.cluster.id, "new"));
    }

    get Math() {
        return Math;
    }

    private agentZStatus(broadcaster: Broadcaster) {
        return !broadcaster.is_enabled
            ? "-"
            : this.bs.checkAgentZHasRecentReport(broadcaster) && broadcaster.agentz_installed
            ? this.translate.instant("WORKING")
            : !this.bs.checkAgentZHasRecentReport(broadcaster) && broadcaster.agentz_installed
            ? this.translate.instant("NOT_REPORTING")
            : !broadcaster.agentz_installed
            ? this.translate.instant("NOT_INSTALLED")
            : "-";
    }
}
