<spinner-animation *ngIf="loading"></spinner-animation>
<app-table-list
    [tableName]="'broadcaster-active-objects-2'"
    [displayTableName]="'ACTIVE_OBJECTS' | translate"
    [data]="objects$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showReport]="false"
    *ngIf="!loading"
    [showPagination]="true"
    [showFilter]="true"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    (currentSortDirection)="onSort($event)"
    [autoRows]="autoRows"
    [(selectedRows)]="selectedRows"
    ><div *ngIf="selectedRows.length > 0">
        <app-zx-action-buttons
            (multiToggleMute)="multiToggleMute($event)"
            (multiEdit)="multiEdit($event)"
            (multiDelete)="multiDelete($event)"
            (multiReset)="multiReset($event)"
            [resetButton]="true"
            [toggleStateButton]="false"
            [enableButton]="false"
        >
        </app-zx-action-buttons>
    </div>
</app-table-list>
