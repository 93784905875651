import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { switchMap, map, Observable, of, forkJoin } from "rxjs";
import { catchError } from "rxjs/operators";
import { Constants } from "src/app/constants/constants";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { Source } from "src/app/models/shared";
import {
    AdaptiveChannel,
    DeliveryChannel,
    ZixiPullTarget,
    ZixiPushTarget,
    PublishingTarget,
    RistTarget,
    UdpRtpTarget,
    RtmpPushTarget,
    SrtTarget,
    NdiTarget
} from "src/app/pages/channels/channel";

export enum OBJECT_TYPE {
    SOURCE = "source",
    ADAPTIVE_CHANNEL = "adaptive_channel",
    DELIVERY_CHANNEL = "delivery_channel",
    ZIXI_PULL_TARGET = "zixi_pull",
    ZIXI_PUSH_TARGET = "zixi_push",
    HTTP_PUBLISHING_TARGET = "publishing_target",
    RIST_TARGET = "rist",
    UDP_RTP_TARGET = "udp_rtp",
    RTMP_PUSH_TARGET = "rtmp_push",
    SRT_TARGET = "srt_targets",
    NDI_TARGET = "ndi_targets"
}

type ACTIVE_OBJECT_MODELS =
    | Source
    | AdaptiveChannel
    | DeliveryChannel
    | ZixiPullTarget
    | ZixiPushTarget
    | PublishingTarget
    | RistTarget
    | UdpRtpTarget
    | RtmpPushTarget
    | SrtTarget
    | NdiTarget;

interface ActiveObjectsIdsByType {
    [OBJECT_TYPE.SOURCE]?: number[];
    [OBJECT_TYPE.ADAPTIVE_CHANNEL]?: number[];
    [OBJECT_TYPE.DELIVERY_CHANNEL]?: number[];
    [OBJECT_TYPE.ZIXI_PULL_TARGET]?: number[];
    [OBJECT_TYPE.ZIXI_PUSH_TARGET]?: number[];
    [OBJECT_TYPE.HTTP_PUBLISHING_TARGET]?: number[];
    [OBJECT_TYPE.RIST_TARGET]?: number[];
    [OBJECT_TYPE.UDP_RTP_TARGET]?: number[];
    [OBJECT_TYPE.RTMP_PUSH_TARGET]?: number[];
    [OBJECT_TYPE.SRT_TARGET]?: number[];
    [OBJECT_TYPE.NDI_TARGET]?: number[];
}

export interface ActiveObject {
    model: ACTIVE_OBJECT_MODELS;
    type:
        | OBJECT_TYPE.SOURCE
        | OBJECT_TYPE.ADAPTIVE_CHANNEL
        | OBJECT_TYPE.DELIVERY_CHANNEL
        | OBJECT_TYPE.ZIXI_PULL_TARGET
        | OBJECT_TYPE.ZIXI_PUSH_TARGET
        | OBJECT_TYPE.HTTP_PUBLISHING_TARGET
        | OBJECT_TYPE.RIST_TARGET
        | OBJECT_TYPE.UDP_RTP_TARGET
        | OBJECT_TYPE.RTMP_PUSH_TARGET
        | OBJECT_TYPE.SRT_TARGET
        | OBJECT_TYPE.NDI_TARGET;
}

@Injectable({
    providedIn: "root"
})
export class BroadcasterActiveObjectsService {
    readonly SUPPORTED_OBJECTS_TYPES = Object.values(OBJECT_TYPE) as string[];
    readonly TYPE_TO_DATA_FUNCTION_MAP = {
        [OBJECT_TYPE.SOURCE]: this.sourcesService.getSourcesNew.bind(this.sourcesService),
        [OBJECT_TYPE.ADAPTIVE_CHANNEL]: this.channelsService.getAdaptiveChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE.DELIVERY_CHANNEL]: this.channelsService.getDeliveryChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE.ZIXI_PULL_TARGET]: this.targetsService.getPullTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.ZIXI_PUSH_TARGET]: this.targetsService.getPushTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.HTTP_PUBLISHING_TARGET]: this.targetsService.getHttpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.RIST_TARGET]: this.targetsService.getRistTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.UDP_RTP_TARGET]: this.targetsService.getUdpRtpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.RTMP_PUSH_TARGET]: this.targetsService.getRtmpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.SRT_TARGET]: this.targetsService.getSrtTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.NDI_TARGET]: this.targetsService.getNdiTargetsNew.bind(this.targetsService)
    };

    constructor(
        private http: HttpClient,
        private sourcesService: SourcesService,
        private channelsService: ChannelsService,
        private targetsService: TargetsService
    ) {}

    public getActiveObjectsStream(broadcasterId: number): Observable<ActiveObject[]> {
        return of(broadcasterId).pipe(
            switchMap(broadcasterId => this.getActiveObjectsTypes(broadcasterId)),
            switchMap(activeObjectsTypes => {
                const activeObjectsStreams: Observable<ActiveObject[]>[] = [];
                for (const type in activeObjectsTypes) {
                    const ids = activeObjectsTypes[type];
                    const getObjectsDataFunction = this.TYPE_TO_DATA_FUNCTION_MAP[type] as (
                        ids: number[]
                    ) => Observable<ACTIVE_OBJECT_MODELS[]>;
                    const activeObjects$ = getObjectsDataFunction(ids).pipe(
                        map(activeObjects => {
                            if (!activeObjects) {
                                return null;
                            }
                            return activeObjects.map(activeObject => {
                                return {
                                    model: activeObject,
                                    type
                                } as ActiveObject;
                            });
                        })
                    );
                    activeObjectsStreams.push(activeObjects$);
                }

                if (!activeObjectsStreams.length) {
                    return of([]);
                }
                return forkJoin(activeObjectsStreams).pipe(
                    map((activeObjects: ActiveObject[][]) => {
                        return activeObjects
                            .filter(activeObjects => !!activeObjects)
                            .reduce((acc, objectsTypeData) => acc.concat(objectsTypeData), []);
                    })
                );
            })
        );
    }

    private getActiveObjectsTypes(broadcasterId): Observable<ActiveObjectsIdsByType> {
        const req$ = this.http.get<{
            success: boolean;
            result: {
                object_type: string;
                object_id: number;
            }[];
        }>(Constants.apiUrl + Constants.apiUrls.broadcaster + "/" + broadcasterId + "/objects");

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error("Fail to fetch active objects data");
                }

                const activeObjectIdsByType = response.result.reduce((acc, currentValue) => {
                    const objectTypeKey = currentValue.object_type;

                    if (!acc[objectTypeKey]) {
                        acc[objectTypeKey] = [];
                    }
                    acc[objectTypeKey].push(currentValue.object_id);
                    return acc;
                }, {});

                const unsupportedTypes = [];
                for (const key in activeObjectIdsByType) {
                    const isSupported = this.SUPPORTED_OBJECTS_TYPES.includes(key);
                    if (!isSupported) {
                        unsupportedTypes.push(key);
                        delete activeObjectIdsByType[key];
                    }
                }

                if (unsupportedTypes.length) {
                    // TODO: in the future send to sentry
                    // eslint-disable-next-line no-console
                    console.error(
                        `Broadcaster Active Objects: couldn't render unsupported types ${unsupportedTypes.join(", ")}`
                    );
                }

                return activeObjectIdsByType;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.error(error);
                return of({});
            })
        );
    }
}
