import _ from "lodash";
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    AfterViewInit,
    HostListener,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "./../../../constants/constants";
import { Tag, Broadcaster, UserPermissions, BroadcasterAutoRecoveryParamsModel } from "./../../../models/shared";
import { ModalService } from "./../../../components/shared/modals/modal.service";
import { SharedService } from "./../../../services/shared.service";
import { UsersService } from "./../../account-management/users/users.service";
import { BroadcastersService } from "./../../../components/broadcasters/broadcasters.service";
import { Cluster } from "./../cluster";
import { ClustersService } from "./../clusters.service";

import { MixpanelService } from "./../../../services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "./../../../services/title.service";
import { GraphsService } from "./../../../services/graphs.service";
import { PercentPipe, DecimalPipe, DatePipe } from "@angular/common";
import { urlBuilder } from "@zixi/shared-utils";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { ResizeService } from "src/app/services/resize.service";
import { VersionPipe } from "src/app/pipes/version.pipe";
import { BroadcasterLayouts } from "./broadcaster.layout";

@Component({
    selector: "app-broadcaster",
    templateUrl: "./broadcaster.component.html",
    providers: [PercentPipe, DecimalPipe, DatePipe]
})
export class BroadcasterComponent extends BroadcasterLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    canDeactivate() {
        return true;
    }

    constants = Constants;
    cluster: Cluster;
    broadcaster: Broadcaster;
    clusterId: number;
    broadcasterId: number;
    resourceTags: Tag[];
    userPermissions: UserPermissions;
    isZixiUser: boolean = undefined;
    refreshing = false;

    loadingDetails = true;
    canEditBroadcaster = false;
    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    urlBuilder = urlBuilder;
    widgetsToRemoveOrAdd = [];

    private clustersSubscription: Subscription;
    private broadcastersSubscription: Subscription;
    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private resizeSubscription: Subscription;

    protected scopeName = "bxDetails";

    primaryDetails = this.bxPrimaryDetails;
    secondaryDetails = this.bxSecondaryDetails;
    widgetHeaders = this.bxWidgetHeaders;
    widgets = this.broadcasterWidgets;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cs: ClustersService,
        private modalService: ModalService,
        protected sharedService: SharedService,
        protected userService: UsersService,
        protected bs: BroadcastersService,
        protected gs: GraphsService,
        private mixpanelService: MixpanelService,
        protected translate: TranslateService,
        private titleService: TitleService,
        protected percentPipe: PercentPipe,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        protected uptimePipe: UptimePipe,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private resizeService: ResizeService,
        private versionPipe: VersionPipe
    ) {
        super(translate, gs, userService, sharedService);

        this.route.paramMap.subscribe(params => {
            this.clusterId = urlBuilder.decode(params.get("clusterId"));
            this.broadcasterId = urlBuilder.decode(params.get("broadcasterId"));

            if (this.clusterId) this.cluster = this.cs.getCachedCluster(null, this.clusterId);
            if (!this.cluster) return this.router.navigate([Constants.urls.clusters]);
            if (this.broadcasterId) this.broadcaster = this.bs.getCachedBroadcaster(this.broadcasterId);

            this.refreshCluster();
            this.bs.refreshBroadcasters(this.cluster.id, true);
            this.updateAllThings();
        });

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isZixiUser = !!(user.is_zixi_admin || user.is_zixi_support_write || user.is_zixi_support);
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
                this.addOrRemoveFromDetails();
            });
    }

    updateAllThings() {
        this.updateCanEditBroadcaster();
        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
    }

    updateWidgetsToRemoveOrAdd() {
        this.widgetsToRemoveOrAdd = [
            { title: this.translatedNames.CHANGES, toHide: !this.canEditBroadcaster },
            { title: this.translatedNames.AGENTZ_OS, toHide: !this.isZixiUser }
        ];
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    config() {
        this.bs.broadcasterConfigHelp(this.broadcaster);
    }

    hasGPU() {
        return (
            this.broadcaster.recentLoad &&
            (this.broadcaster.recentLoad.nvidia_utilization === 0 || this.broadcaster.recentLoad.nvidia_utilization > 0)
        );
    }

    protected addOrRemoveFromDetails() {
        const hasGPU = this.hasGPU();
        const gpuIndex = this.primaryDetails.findIndex(details => details.title === this.translate.instant("GPU"));
        if (hasGPU) this.primaryDetails[gpuIndex].isHidden = false;
        else if (gpuIndex !== -1) this.primaryDetails[gpuIndex].isHidden = true;

        const connectionIndex = this.secondaryDetails.findIndex(
            details => details.title === this.translatedNames.CONNECTION_STATUS
        );
        if (!this.isZixiUser) this.secondaryDetails[connectionIndex].isHidden = true;

        const openIndex = this.secondaryDetails.findIndex(details => details.title === this.translatedNames.OPEN);
        if (!this.canEditBroadcaster) this.secondaryDetails[openIndex].isHidden = true;

        this.primaryDetails = [...this.primaryDetails];
        this.secondaryDetails = [...this.secondaryDetails];
    }

    protected updateDetailContent(title: string) {
        switch (title) {
            // Broadcaster
            case this.translatedNames.CPU:
                return {
                    content: this.getPercentContent(this.broadcaster.status?.cpu),
                    statusClass: this.getStatusClass(this.broadcaster.status?.cpu),
                    unit: "%"
                };
            case this.translatedNames.RAM:
                return {
                    content: this.getPercentContent(this.broadcaster.status?.ram),
                    statusClass: this.getStatusClass(this.broadcaster.status?.ram),
                    unit: "%"
                };
            case this.translatedNames.HDD:
                return {
                    content: this.getPercentContent(
                        Math.max(this.broadcaster.status?.disk_space, this.broadcaster.status?.disk_space_install)
                    ),
                    statusClass: this.getStatusClass(
                        this.broadcaster.status
                            ? Math.max(this.broadcaster.status?.disk_space, this.broadcaster.status?.disk_space_install)
                            : null,
                        90,
                        95
                    ),
                    unit: "%"
                };
            case this.translatedNames.OUT_BITRATE:
                return {
                    content: this.sharedService.getKBPSContent(this.broadcaster.status?.output_kbps),
                    unit: "kbps"
                };
            case this.translatedNames.IN_BITRATE:
                return {
                    content: this.sharedService.getKBPSContent(this.broadcaster.status?.input_kbps),
                    unit: "kbps"
                };
            case this.translatedNames.GPU:
                return {
                    content: this.getPercentContent(this.broadcaster.recentLoad?.nvidia_utilization),
                    statusClass: this.getStatusClass(this.broadcaster.recentLoad?.nvidia_utilization),
                    unit: "%"
                };
            case this.translatedNames.MEM:
                return {
                    content: this.getPercentContent(this.broadcaster.recentLoad?.nvidia_mem_utilization),
                    statusClass: this.getStatusClass(this.broadcaster.recentLoad?.nvidia_mem_utilization),
                    unit: "%"
                };
            case this.translatedNames.ENC:
                return {
                    content: this.getPercentContent(this.broadcaster.recentLoad?.nvidia_encoder_utilization),
                    statusClass: this.getStatusClass(this.broadcaster.recentLoad?.nvidia_encoder_utilization),
                    unit: "%"
                };
            case this.translatedNames.DEC:
                return {
                    content: this.getPercentContent(this.broadcaster.recentLoad?.nvidia_decoder_utilization),
                    statusClass: this.getStatusClass(this.broadcaster.recentLoad?.nvidia_decoder_utilization),
                    unit: "%"
                };
            case this.translatedNames.INPUTS:
                return {
                    content:
                        this.broadcaster.status?.inputs_count && this.broadcaster.status.inputs_count !== 0
                            ? this.broadcaster.status?.inputs_count
                            : "-"
                };
            case this.translatedNames.ADAPTIVE:
                return {
                    content:
                        this.broadcaster.status?.adaptives_count && this.broadcaster.status.adaptives_count !== 0
                            ? this.broadcaster.status?.adaptives_count
                            : "-"
                };
            case this.translatedNames.OUTPUTS:
                return {
                    content:
                        this.broadcaster.status?.outputs_count && this.broadcaster.status.outputs_count !== 0
                            ? this.broadcaster.status?.outputs_count
                            : "-"
                };
            case this.translatedNames.API_CREDENTIALS:
                return {
                    content: this.broadcaster.api_password,
                    object: this.broadcaster
                };
            case this.translatedNames.OPEN:
                return {
                    content: this.broadcaster.configure_link ? "Broadcaster" : "",
                    link: this.broadcaster.configure_link ?? "",
                    fa_icon: "external-link-alt"
                };
            case this.translatedNames.ACTIVATION_KEY_LINK:
                return {
                    content: this.broadcaster.status?.activation_key_link ? this.cluster?.activation_key : "",
                    link: this.broadcaster.status?.activation_key_link ?? "",
                    fa_icon: "external-link-alt",
                    canCopy: () => true
                };
            case this.translatedNames.AGENTZ:
                return {
                    content: !this.broadcaster.is_enabled
                        ? "-"
                        : this.bs.checkAgentZHasRecentReport(this.broadcaster) && this.broadcaster.agentz_installed
                        ? this.translate.instant("WORKING")
                        : !this.bs.checkAgentZHasRecentReport(this.broadcaster) && this.broadcaster.agentz_installed
                        ? this.translate.instant("NOT_REPORTING")
                        : !this.broadcaster.agentz_installed
                        ? this.translate.instant("NOT_INSTALLED")
                        : "-"
                    // canCopy: () => true
                };
            case this.translatedNames.HOST_ID:
                return {
                    content: this.broadcaster.status?.hostid ?? "",
                    canCopy: () => true
                };
            case this.translatedNames.PUBLIC_IP:
                return {
                    content: this.broadcaster.status?.ip ?? "",
                    canCopy: () => true
                };
            case this.translatedNames.PRIVATE_IP:
                return {
                    content:
                        this.broadcaster.status?.private_ip &&
                        this.broadcaster.status?.private_ip !== this.broadcaster.status?.ip
                            ? this.broadcaster.status?.private_ip
                            : "",
                    canCopy: () => true
                };
            case this.translatedNames.VERSION:
                return {
                    content: `${this.versionPipe.transform(this.broadcaster.status?.about?.version || "")}${
                        this.broadcaster.can_transcode ? " Transcoder" : ""
                    }`,
                    canCopy: () => true
                };
            case this.translatedNames.INSTANCE_ID:
                return {
                    content: this.broadcaster?.broadcaster_cluster?.aws_account_id ? this.broadcaster.cloud_id : "",
                    canCopy: () => true
                };
            case this.translatedNames.INSTANCE_TYPE:
                return {
                    content: this.broadcaster.instance_type ?? "",
                    canCopy: () => true
                };
            case this.translatedNames.SYSTEM_LOGS:
                return {
                    content: this.translate.instant("DOWNLOAD_LOGS"),
                    link: "/api/broadcasters/" + this.broadcaster.id + "/system_logs",
                    fa_icon: "download"
                };
            case this.translatedNames.UP_TIME:
                return {
                    content: this.uptimePipe.transform(this.broadcaster.status?.up_time_seconds)
                };
            case this.translatedNames.CREATED_AT:
                return {
                    content: this.datePipe.transform(this.broadcaster.created_at, "MMM d, y, h:mm:ss a")
                };
            case this.translatedNames.PRIMARY:
                return {
                    content: !this.broadcaster.is_backup ? "Yes" : "No"
                };
            case this.translatedNames.HOSTNAME:
                return {
                    content: this.broadcaster.hostname ?? "",
                    canCopy: () => true
                };
            case this.translatedNames.LOCATION:
                return {
                    content: (this.broadcaster.location?.ip || this.broadcaster.location?.address)?.display_name
                        ? "-"
                        : "",
                    object: this.broadcaster
                };
            case this.translatedNames.NETWORK:
                return {
                    content: this.broadcaster.location?.ip
                        ? [
                              this.broadcaster.location?.ip?.isp,
                              this.broadcaster.location?.ip?.region_name,
                              this.broadcaster.location?.ip?.country_name
                          ]
                              .filter(e => !!e)
                              .join(", ")
                        : ""
                };
            case this.translatedNames.CONNECTION_STATUS:
                return {
                    content: this.bs.getTunnelStatusText(this.bs.getTunnelStatus(this.broadcaster)),
                    status: this.bs.getTunnelStatus(this.broadcaster)
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isBroadcasterMultiselect"))
            this.isMultiSelect = localStorage.getItem("isBroadcasterMultiselect") === "true" ? true : false;

        // subs
        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());

        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        this.clustersSubscription = this.cs.clusters.subscribe(async clusters => {
            this.cluster = clusters.find((c: Cluster) => c.id === this.clusterId);
            this.updateCanEditBroadcaster();
            this.updateWidgetsToRemoveOrAdd();
        });

        this.broadcastersSubscription = this.bs.broadcasters.subscribe(broadcasters => {
            const bcasters = broadcasters.filter(b => b.broadcaster_cluster_id === this.cluster.id);
            this.broadcaster = bcasters.find((b: Broadcaster) => b.id === this.broadcasterId);

            // Broadcaster no longer exists
            if (!this.broadcaster)
                return this.router.navigate(urlBuilder.getRegularClusterUrl(this.cluster.id, this.cluster.name));

            if (this.broadcaster.hasFullDetails) {
                this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
                this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
                this.loadingDetails = false;
            } else {
                this.bs.refreshBroadcaster(this.broadcaster.id, true);
            }

            // Set Title
            this.titleService.setTitle(
                this.translatedNames.BROADCASTER_CLUSTER +
                    " - " +
                    this.cluster.name +
                    " / " +
                    this.translatedNames.BROADCASTER +
                    " - " +
                    this.broadcaster.name
            );

            this.updateCanEditBroadcaster();
            this.updateWidgetsToRemoveOrAdd();
            this.addOrRemoveFromDetails();
            this.getDetailsAreaHeights();
        });

        this.sharedService
            .getResourceTagsByType("resource")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
                this.updateCanEditBroadcaster();
                this.addOrRemoveFromWidgets();
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateCanEditBroadcaster();
            this.addOrRemoveFromWidgets();
        });

        // set initial layout
        await this.revertLayoutChanges();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);
    }

    ngOnDestroy() {
        this.clustersSubscription?.unsubscribe();
        this.broadcastersSubscription?.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
    }

    close() {
        this.cs.refreshClusters(true);
        this.router.navigate([Constants.urls.clusters]);
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.cs.refreshCluster(this.clusterId, true));
        this.refreshing = false;
        return firstValueFrom(this.bs.refreshBroadcaster(this.broadcaster.id, true));
    }

    async toggle(broadcaster: Broadcaster) {
        if (broadcaster.is_enabled === 1) {
            await this.disableBroadcater(broadcaster);
        } else {
            await this.enableBroadcaster(broadcaster);
        }
    }

    async enableBroadcaster(broadcaster: Broadcaster) {
        const model = {
            is_enabled: 1
        };

        await this.modalService.confirm(
            "ENABLE",
            "BROADCASTER",
            async () => {
                const result = await this.bs.updateBroadcaster(this.broadcaster, model);
                if (result !== false) {
                    this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " broadcaster", {
                        id: broadcaster.id
                    });
                    this.refreshCluster();
                    return true;
                } else {
                    return false;
                }
            },
            this.broadcaster.name
        );
    }

    async disableBroadcater(broadcaster: Broadcaster) {
        type DisableRequest = {
            is_enabled: 0;
        } & Partial<BroadcasterAutoRecoveryParamsModel>;

        const model: DisableRequest = {
            is_enabled: 0
        };

        await this.modalService.confirmDeleteDisableBroadcasterWithRecovery(
            "DISABLE",
            broadcaster.name,
            broadcaster.id,
            this.cluster.broadcasters,
            async (autorecover: boolean, target_broadcater_id: number | null) => {
                if (autorecover) {
                    model.auto_recovery = { exclude_broadcaster_id: broadcaster.id };
                    if (target_broadcater_id) {
                        model.auto_recovery.target_broadcaster_id = target_broadcater_id;
                    }
                }

                const result = await this.bs.updateBroadcaster(broadcaster, model);
                if (result !== false) {
                    this.mixpanelService.sendEvent(this.translate.instant("DISABLE").toLowerCase() + " broadcaster", {
                        id: broadcaster.id
                    });
                    this.cs.refreshCluster(this.cluster.id, true);
                    return true;
                } else {
                    return false;
                }
            }
        );
    }

    async toggleMaintenance() {
        const model = {
            maintenance: !this.broadcaster.maintenance
        };

        const result = await this.bs.updateBroadcaster(this.broadcaster, model);
        if (result !== false) {
            this.refreshCluster();
            return true;
        } else {
            return false;
        }
    }

    async deleteBroadcaster(broadcaster: Broadcaster) {
        await this.modalService.confirmDeleteDisableBroadcasterWithRecovery(
            "DELETE",
            broadcaster.name,
            broadcaster.id,
            this.cluster.broadcasters,
            async (autorecover: boolean, target_broadcater_id: number | null) => {
                let additionalData: { exclude_broadcaster_id?: number; target_broadcaster_id?: number };

                if (autorecover) {
                    additionalData = { exclude_broadcaster_id: broadcaster.id };
                    if (target_broadcater_id) {
                        additionalData.target_broadcaster_id = target_broadcater_id;
                    }
                }

                const id = broadcaster.id;
                const result = await this.bs.deleteBroadcaster(
                    broadcaster,
                    additionalData ? { auto_recovery: additionalData } : undefined
                );
                if (result !== false) {
                    this.mixpanelService.sendEvent("delete broadcaster", { id });
                    this.refreshCluster();
                } else {
                    return false;
                }
            }
        );
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "broadcaster" + " until: " + date.toISOString());
        await this.bs.updateBroadcaster(this.broadcaster, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.broadcaster.active_mute ? "unmute" : "mute") + " broadcaster");
        this.bs.updateBroadcaster(this.broadcaster, {
            muted: !this.broadcaster.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    multiSelectChanged(bool: boolean) {
        this.isMultiSelect = bool;
        localStorage.setItem("isBroadcasterMultiselect", this.isMultiSelect.toString());
    }

    public refreshBroadcaster = () => {
        this.bs.refreshBroadcaster(this.broadcaster.id, true);
    };

    private updateCanEditBroadcaster() {
        this.canEditBroadcaster =
            this.broadcaster &&
            this.sharedService.canEditZixiObject(this.cluster, this.resourceTags, this.userPermissions);
    }

    private refreshCluster() {
        return this.cs.refreshCluster(this.cluster.id, true);
    }

    refreshBroadcasterPromise() {
        return firstValueFrom(this.cs.refreshCluster(this.cluster.id, true));
    }

    private getPercentContent(value?: number) {
        return value || value === 0 ? this.decimalPipe.transform(value, "1.0-1") : "-";
    }

    private getStatusClass(
        value?: number,
        minValue = 50,
        maxValue = 75
    ): "status-good" | "status-warning" | "status-error" | "" {
        if (value === null) return "";
        if (value === undefined) return "";
        if (value < minValue) return "status-good";
        if (value >= maxValue) return "status-error";
        return "status-warning";
    }
}
