import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { ClustersService } from "../clusters.service";
import { Cluster } from "../cluster";

@Injectable({
    providedIn: "root"
})
export class ClusterListResolverService implements Resolve<Cluster[]> {
    constructor(private cs: ClustersService) {}

    resolve(): Observable<Cluster[]> | Observable<never> {
        return Observable.create((observe: Subscriber<Cluster[]>) => {
            this.cs.refreshClusters().subscribe((clusters: Cluster[]) => {
                observe.next(clusters);
                observe.complete();
            });
        });
    }
}
