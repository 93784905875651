import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, map, forkJoin } from "rxjs";

import { Cluster } from "../cluster";
import { urlBuilder } from "@zixi/shared-utils";
import { ClustersService } from "../clusters.service";

import { Breadcrumb } from "src/app/services/breadcrumbs.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { Broadcaster } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class BroadcasterBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private clustersService: ClustersService, private broadcastersService: BroadcastersService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        const clusterObservable = this.clustersService.refreshCluster(
            urlBuilder.decode(route.params["clusterId"]),
            false
        );
        const brodcasterObservable = this.broadcastersService.refreshBroadcaster(
            urlBuilder.decode(route.params["broadcasterId"]),
            false
        );

        return forkJoin([clusterObservable, brodcasterObservable]).pipe(
            map((results: [Cluster, Broadcaster]) => [
                {
                    label: results[0].name,
                    url: `${Constants.urls.clusters}/${urlBuilder.encode(results[0].id)}/${results[0].name}`
                },
                {
                    label: results[1].name,
                    url: state.url
                }
            ])
        );
    }
}

@Injectable({
    providedIn: "root"
})
export class BroadcasterFormBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(
        private clustersService: ClustersService,
        private broadcastersService: BroadcastersService,
        private translate: TranslateService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        const clusterObservable = this.clustersService.refreshCluster(
            urlBuilder.decode(route.params["clusterId"]),
            false
        );
        const brodcasterObservable = this.broadcastersService.refreshBroadcaster(
            urlBuilder.decode(route.params["broadcasterId"]),
            false
        );

        return forkJoin([clusterObservable, brodcasterObservable]).pipe(
            map((results: [Cluster, Broadcaster]) => [
                {
                    label: this.translate.instant("BROADCASTER_CLUSTERS"),
                    url: "/" + route.url[0].path
                },
                {
                    label: results[0].name,
                    url: `${Constants.urls.clusters}/${urlBuilder.encode(results[0].id)}/${results[0].name}`
                },
                {
                    label: results[1].name,
                    url:
                        "/" +
                        route.url[0].path +
                        "/" +
                        route.url[1].path +
                        "/" +
                        route.url[2].path +
                        "/" +
                        route.url[3].path +
                        "/" +
                        route.url[4].path
                },
                {
                    label:
                        this.translate.instant(route.params.action.toUpperCase()) +
                        " " +
                        this.translate.instant("BROADCASTER")
                }
            ])
        );
    }
}
