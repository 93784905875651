import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Cluster } from "../../cluster";
import { RedirectionRouting, TARGET_CIDR } from "@zixi/models";
import { KeyMap } from "../../../../models/shared";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { DatePipe } from "@angular/common";
import { SharedService } from "src/app/services/shared.service";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { ClustersService } from "../../clusters.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-cluster-redirection-rules",
    templateUrl: "./cluster-redirection-rules.component.html",
    providers: [DatePipe]
})
export class ClusterRedirectionRulesComponent implements OnChanges {
    @Input() cluster: Cluster;
    @Input() canEdit: boolean;

    redirectionRoutingsBS$ = new BehaviorSubject<RedirectionRouting[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<RedirectionRouting>>[] = [
        {
            header: this.translate.instant("DESCRIPTION"),
            columnDef: "description",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.description,
                row => row.description,
                () => true
            ),
            sortBy: (row: KeyMap<RedirectionRouting>) => row.description
        },
        {
            header: this.translate.instant("FROM"),
            columnDef: "from",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.getFromTitle(row),
                row => this.getFromTitle(row),
                () => true
            ),
            sortBy: (row: KeyMap<RedirectionRouting>) => this.getFromTitle(row)
        },
        {
            header: this.translate.instant("TO"),
            columnDef: "to",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.getToTitle(row),
                row => this.getToTitle(row),
                () => true
            ),
            sortBy: (row: KeyMap<RedirectionRouting>) => this.getToTitle(row)
        },
        {
            header: this.translate.instant("LATENCY_OVERRIDE_MS"),
            columnDef: "latency",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<RedirectionRouting>>(
                row => (row.latency === null ? "-" : row.latency.toString()),
                row => (row.latency === null ? "-" : row.latency.toString()),
                () => true
            ),
            sortBy: (row: KeyMap<RedirectionRouting>) => row.latency
        },
        {
            header: this.translate.instant("ORDER"),
            columnDef: "order",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<RedirectionRouting>>(
                row => (row.order === null ? "-" : row.order.toString()),
                row => (row.order === null ? "-" : row.order.toString()),
                () => true
            ),
            sortBy: (row: KeyMap<RedirectionRouting>) => row.order
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            stickyToLast: true,
            align: "right",
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<RedirectionRouting, Promise<void>>({
                deleteRef: row => this.onDeleteAction(this.cluster.id, row.id),
                canDeleteCallBack: () => this.canEdit,
                editRef: row => this.openRedirectionRuleDialog(row),
                canEditCallBack: () => this.canEdit
            })
        }
    ];

    constructor(
        private clusterService: ClustersService,
        private translate: TranslateService,
        private sharedService: SharedService,
        private modalService: ModalService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cluster.firstChange || changes.cluster.currentValue.id !== changes.cluster.previousValue.id) {
            this.updateRedirectionRoutingsBS$(this.cluster.redirectionRoutings);
        }
    }

    async onDeleteAction(clusterId: number, RedirectionRoutingId: number) {
        await this.modalService.confirm("DELETE", "REDIRECTION_RULE", async () => {
            const isDeleted = await this.clusterService.deleteRedirectionRouting(clusterId, RedirectionRoutingId);
            if (!isDeleted) {
                return false;
            }
            const updatedCluster = await firstValueFrom(this.clusterService.refreshCluster(this.cluster.id, true));
            this.updateRedirectionRoutingsBS$(updatedCluster.redirectionRoutings);
            return true;
        });
    }

    async openRedirectionRuleDialog(redirectionRouting: RedirectionRouting = null) {
        const onSuccessHandler = async () => {
            const updatedCluster = await firstValueFrom(this.clusterService.refreshCluster(this.cluster.id, true));
            this.updateRedirectionRoutingsBS$(updatedCluster.redirectionRoutings);
        };
        await this.modalService.openClusterRedirectionRuleDialog(this.cluster.id, onSuccessHandler, redirectionRouting);
    }

    updateRedirectionRoutingsBS$(redirectionRoutings: RedirectionRouting[]) {
        const sortedRedirectionRoutings = this.sharedService.sort([...redirectionRoutings], "order", "asc");
        this.redirectionRoutingsBS$.next(sortedRedirectionRoutings);
    }

    getFromTitle(redirectionRouting: RedirectionRouting) {
        if (redirectionRouting.client_cidr && redirectionRouting.client_cluster_id === null) {
            return redirectionRouting.client_cidr;
        }
        if (redirectionRouting.client_cluster_id && redirectionRouting.client_cidr === null) {
            return redirectionRouting.clientCluster?.name;
        }
    }

    getToTitle(redirectionRouting: RedirectionRouting) {
        if (redirectionRouting.target_cidr === TARGET_CIDR.PUBLIC) {
            return `${this.translate.instant("REDIRECT_TO")} ${this.translate.instant("PUBLIC_IP")}`;
        }
        if (redirectionRouting.target_cidr === TARGET_CIDR.PRIVATE) {
            return `${this.translate.instant("REDIRECT_TO")} ${this.translate.instant("PRIVATE_IP")}`;
        }
        if (redirectionRouting.target_cidr === TARGET_CIDR.BLOCK) {
            return this.translate.instant("BLOCK");
        }
        return `${this.translate.instant("REDIRECT_TO")} ${redirectionRouting.target_cidr}`;
    }
}
