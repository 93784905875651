<!-- Details Panel -->
<content-loading-animation *ngIf="loadingDetails && !cluster"></content-loading-animation>

<!-- New -->
<div class="details-panel new-page" *ngIf="cluster && !loadingDetails">
    <!-- Header -->
    <app-details-page-header [tags]="cluster.resourceTags" [canEdit]="canEdit(cluster)" [canDisable]="canEdit(cluster)" (closePage)="close()">
        <zx-cluster title [model]="cluster" [showStatusIcon]="true" [showStatusText]="false" [showLink]="false" [showTag]="false"></zx-cluster>
        <app-details-page-header-action-buttons
            action-buttons
            [canEdit]="canEdit(cluster)"
            [canToggle]="false"
            [muted]="cluster.active_mute"
            [editUrl]="urlBuilder.getClusterActionUrl(cluster.id, cluster.name, 'edit')"
            (toggleMute)="toggleMute()"
            (refresh)="refresh()"
            [refreshing]="refreshing"
        >
        </app-details-page-header-action-buttons>
        <app-details-page-header-drop-down
            drop-down
            [canEdit]="canEdit(cluster)"
            (delete)="deleteCluster()"
            [linkUrl]="false"
            [terminationProtection]="cluster.termination_protection"
            [add]="{ route: urlBuilder.getBroadcasterAddActionUrl(cluster.id, 'new'), text: ('ADD_BROADCASTER' | translate) }"
            (muteUntil)="muteUntil($event)"
            [muted]="cluster.active_mute"
            (toggleMute)="toggleMute()"
            [editUrl]="urlBuilder.getClusterActionUrl(cluster.id, cluster.name, 'edit')"
            [canToggle]="false"
        ></app-details-page-header-drop-down>
    </app-details-page-header>
    <!-- Content -->
    <spinner-animation *ngIf="!isWidgetFullyLoaded"></spinner-animation>
    <div class="details-content" #scrollArea>
        <div class="scroll-content">
            <ng-container *ngIf="isWidgetFullyLoaded">
                <app-layout-buttons
                    *ngIf="!isMobile"
                    [savingLayout]="savingLayout"
                    (saveLayout)="saveLayout()"
                    (resetLayout)="resetLayout()"
                    (revertLayout)="revertLayoutChanges()"
                    [layoutChanged]="layoutChanged"
                    [(isLocked)]="isLocked"
                    (lockChanged)="lockChanged($event)"
                ></app-layout-buttons>
                <!-- Primary Details -->
                <app-details-section
                    #primaryDetailsArea
                    [(detailsList)]="primaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isPrimaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isPrimaryDetails]="true"
                    [isLocked]="isLocked"
                    (refreshPage)="refresh()"
                    class="primary"
                    [ngClass]="{ sticky: isPrimaryDetailsSectionPinned }"
                ></app-details-section>
                <!-- Secondary Details -->
                <app-details-section
                    #secondaryDetailsArea
                    [(detailsList)]="secondaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isSecondaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isLocked]="isLocked"
                    [(isSectionHidden)]="isSecondaryDetailsSectionHidden"
                    (isSectionHiddenChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [ngClass]="{
                        sticky: isSecondaryDetailsSectionPinned || (isSecondaryDetailsSectionHidden && (isPrimaryDetailsSectionPinned || isWidgetHeaderPinned)),
                        hidden: isSecondaryDetailsSectionHidden
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && (isSecondaryDetailsSectionPinned || isSecondaryDetailsSectionHidden)
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionHidden
                                ? 40
                                : -1,
                        'z-index': constants.zindexValues.second
                    }"
                >
                </app-details-section>
                <!-- Alerts -->
                <div>
                    <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="cluster.active_flapping && cluster.is_enabled">
                        <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }} {{ cluster.flapping | date : "MMM d, y, h:mm:ss a" }}
                    </ngb-alert>
                    <zx-active-states
                        [activeStates]="cluster.activeStates"
                        [refreshFunction]="refreshCluster"
                        [canEdit]="canEdit(cluster)"
                        [objectName]="cluster.name"
                    ></zx-active-states>
                </div>
                <!-- Tabs -->
                <app-widget-section-header
                    [(widgetHeaders)]="widgetHeaders"
                    (widgetSelectedChange)="onWidgetSelectChange($event)"
                    [(isSectionPinned)]="isWidgetHeaderPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isMultiSelect)]="isMultiSelect"
                    (isMultiSelectChange)="multiSelectChanged($event)"
                    [(multiWidgetHeaders)]="multiWidgetHeaders"
                    [isLocked]="isLocked"
                    [isMobile]="isMobile"
                    [ngClass]="{
                        sticky: isWidgetHeaderPinned
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight + secondaryDetailsAreaHeight - 1
                                : isPrimaryDetailsSectionPinned && !isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? secondaryDetailsAreaHeight - 1
                                : -1,
                        'z-index': constants.zindexValues.first
                    }"
                >
                </app-widget-section-header>
                <!-- Content -->
                <app-widget-section
                    [(widgets)]="widgets"
                    (widgetsChange)="onWidgetsLayoutChange($event)"
                    [isMultiSelect]="isMultiSelect"
                    [isLocked]="isLocked"
                ></app-widget-section>
            </ng-container>
        </div>
    </div>
</div>
