<app-table-list
    tableName="redirection-rules"
    class="mt-2"
    [displayTableName]="'REDIRECTION_RULES' | translate"
    [data]="redirectionRoutingsBS$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [autoRows]="false"
    [showPagination]="true"
    [showFilter]="false"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [hoverable]="false"
    [selectable]="false"
>
    <div class="form-group mb-0">
        <button type="button" class="btn btn-outline-primary" (click)="openRedirectionRuleDialog()" [disabled]="!canEdit" title="{{ 'ADD_RULE' | translate }}">
            <fa-icon icon="plus" size="sm"></fa-icon>
            {{ "ADD_RULE" | translate }}
        </button>
    </div>
</app-table-list>
