import { ComponentRef } from "@angular/core";
import { Broadcaster, KeyMap } from "../../../models/shared";
import { ZxClusterComponent } from "./zx-cluster.component";

export function assignComponentsClusterAdapter(
    clusterComponentRef: ComponentRef<ZxClusterComponent>,
    row: KeyMap<Broadcaster>,
    searchTerm: string[]
) {
    const clusterComponentInstance = clusterComponentRef.instance;
    clusterComponentInstance.model = row.broadcaster_cluster;
    clusterComponentInstance.searchTerm = searchTerm;
    clusterComponentInstance.showOtherIcons = false;
    clusterComponentInstance.showLink = true;
    clusterComponentInstance.showStatusIcon = false;
}
