import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "./../../../constants/constants";
import { SharedService } from "./../../../services/shared.service";
import { ClustersService } from "../../../pages/clusters/clusters.service";
import { BroadcastersService } from "./../broadcasters.service";
import { Broadcaster, AccessKey } from "./../../../models/shared";
import { Cluster } from "../../../pages/clusters/cluster";
import { ModalService } from "../../shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-broadcaster-form",
    templateUrl: "./broadcaster-form.component.html",
    providers: [TitleCasePipe]
})
export class BroadcasterFormComponent implements OnInit {
    cluster: Cluster;
    clusterId: number;
    clusterName: string;
    broadcaster: Broadcaster;
    existingBroadcaster: Broadcaster;
    broadcasters: Broadcaster[];
    broadcasterId: number;
    broadcasterName: string;
    broadcasterNames: string[];
    action: string;
    accessKeys: AccessKey[];
    hasRemainingIps: boolean;

    submitted = false;
    minLength = 3;
    isEdit = false;
    constants = Constants;

    loading = true;
    saving = false;
    deleting: boolean;
    showAdvanced = false;
    showPassword = false;

    isAutoDetect: boolean;
    noPrivateIp: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cs: ClustersService,
        private bs: BroadcastersService,
        private modalService: ModalService,
        private sharedService: SharedService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private cbs: ClipboardService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(async params => {
            this.clusterId = urlBuilder.decode(params.get("clusterId"));
            this.clusterName = params.get("clusterName");
            this.action = params.get("action");
            this.broadcasterId = urlBuilder.decode(params.get("broadcasterId"));
            this.broadcasterName = params.get("broadcasterName");

            if (this.action && this.action === "edit") this.isEdit = true;

            if (this.clusterId) {
                this.cluster = Object.assign({}, this.cs.getCachedCluster(null, this.clusterId));
                // Check if found in cache
                if (this.sharedService.isEmptyObject(this.cluster)) {
                    this.cs
                        .refreshClusters(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.cluster = Object.assign({}, this.cs.getCachedCluster(null, this.clusterId));
                            await this.getBroadcasters(this.cluster.id);
                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    await this.getBroadcasters(this.cluster.id);
                    this.prepForm();
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    setTitle() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("BROADCASTER") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.broadcaster && this.broadcaster.name ? this.broadcaster.name : "")
        );
    }

    prepForm() {
        this.setTitle();

        if (this.action) {
            if (this.action === "edit") {
                this.isEdit = true;
            }
            if (this.broadcaster && !this.broadcaster.streaming_ip) this.isAutoDetect = true;
            if (this.broadcaster && !this.broadcaster.private_ip) this.noPrivateIp = true;
        }

        if (!this.broadcaster && !this.isEdit) {
            this.broadcaster = new Broadcaster();
            this.broadcaster.name = null;
            this.broadcaster.is_backup = 0;
            this.broadcaster.api_user = "admin";
            this.isAutoDetect = true;
            this.noPrivateIp = true;
            this.broadcaster.location = {};
        }

        if (this.cluster._frontData?.is_auto_scaling) {
            if (!this.isEdit) {
                this.broadcaster.name = this.generateBroadcasterName();
            }
        }

        this.hasRemainingIps = this.clusterHasRemainingIps();
    }

    clusterHasRemainingIps() {
        if (this.cluster?.broadcasters && (this.cluster.eips || this.cluster.require_eip)) {
            const broadcastersOnCluster = this.cluster.broadcasters;
            const numberOfTotalIps = this.cluster.eips?.split(",").length;
            let numberOfUsedIps = 0;
            broadcastersOnCluster.forEach(broadcaster => {
                if (broadcaster.eip_allocation_id !== null) {
                    numberOfUsedIps++;
                }
            });
            return numberOfTotalIps > numberOfUsedIps;
        } else {
            return true;
        }
    }

    async getBroadcasters(id: number) {
        const result = await this.bs.refreshBroadcasters(id, true).toPromise();

        if (result) {
            this.broadcasters = result;
            this.broadcasterNames = _.map(this.broadcasters, "name");
            // If Edit remove the current broadcasterName from broadcasterNames list
            if (this.isEdit) {
                this.broadcasterNames = _.without(this.broadcasterNames, this.broadcasterName);
            }
            // Set Broadcaster
            if (this.broadcasterId) {
                this.broadcaster = this.broadcasters.find(b => b.id === this.broadcasterId);
                this.existingBroadcaster = _.cloneDeep(this.broadcaster);
            }
            return true;
        } else {
            return false;
        }
    }

    ngOnInit() {
        this.sharedService
            .getAccessKeys()
            .pipe(take(1))
            .subscribe((keys: AccessKey[]) => {
                this.accessKeys = keys;
            });

        this.prepForm();
    }

    async deleteBroadcaster() {
        this.deleting = true;
        const result = await this.bs.deleteBroadcaster(this.broadcaster);
        if (result) this.router.navigate([Constants.urls.clusters]);
        this.deleting = false;
    }

    async onSubmit() {
        this.saving = true;

        const model = this.cluster._frontData.is_auto_scaling
            ? {
                  name: this.broadcaster.name,
                  location: this.broadcaster.location.address,
                  is_backup: this.broadcaster.is_backup,
                  streaming_ip: this.isAutoDetect ? null : this.broadcaster.streaming_ip,
                  private_ip: this.noPrivateIp ? null : this.broadcaster.private_ip,
                  input_bw_limit: this.broadcaster.input_bw_limit,
                  output_bw_limit: this.broadcaster.output_bw_limit
              }
            : {
                  name: this.broadcaster.name,
                  streaming_ip: this.isAutoDetect ? null : this.broadcaster.streaming_ip,
                  private_ip: this.noPrivateIp ? null : this.broadcaster.private_ip,
                  is_backup: this.broadcaster.is_backup,
                  api_user: this.broadcaster.api_user,
                  api_password: this.broadcaster.api_password,
                  remote_access_key_id: this.broadcaster.accessKey.id,
                  broadcaster_cluster_id: !this.isEdit ? this.cluster.id : undefined,
                  input_bw_limit: this.broadcaster.input_bw_limit,
                  output_bw_limit: this.broadcaster.output_bw_limit,
                  location: this.broadcaster.location.address
              };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingBroadcaster, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedBroadcaster = await this.bs.updateBroadcaster(this.broadcaster, {
                    ...changedData,
                    restart_confirmed: false
                });
                // Restart Notice
                if (updatedBroadcaster === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "BROADCASTER",
                        async () => {
                            const updateAndRestartBroadcaster = await this.bs.updateBroadcaster(this.broadcaster, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartBroadcaster && updateAndRestartBroadcaster !== true) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart broadcaster", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularBroadcasterUrl(
                                        this.cluster.id,
                                        updateAndRestartBroadcaster.id,
                                        updateAndRestartBroadcaster.name
                                    )
                                );
                            } else {
                                this.saving = false;
                            }
                        },
                        model.name
                    );
                    this.saving = false;
                } else if (updatedBroadcaster) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update broadcaster", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularBroadcasterUrl(
                            this.cluster.id,
                            this.broadcaster.id,
                            updatedBroadcaster.name
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.cancel();
            }
        } else {
            const result = await this.bs.addBroadcaster(this.cluster.id, model);
            if (result) {
                this.mixpanelService.sendEvent("create broadcaster");
                this.router.navigate(urlBuilder.getRegularBroadcasterUrl(this.cluster.id, result.id, result.name));
            }
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit) {
            this.router.navigate(
                urlBuilder.getRegularBroadcasterUrl(this.cluster.id, this.broadcaster.id, this.broadcaster.name)
            );
        } else {
            this.router.navigate(urlBuilder.getRegularClusterUrl(this.cluster.id, this.cluster.name));
        }
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    generateBroadcasterName() {
        let counter = this.cluster.broadcasters.length + 1;
        do {
            const bxName = `${this.cluster.name}-${counter}`;
            if (!this.cluster.broadcasters.find(b => b.name === bxName)) return bxName;
            counter++;
        } while (true);
    }
}
