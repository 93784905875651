import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { ZxEventsComponent } from "src/app/components/shared/zx-events/zx-events.component";
import { Constants } from "src/app/constants/constants";
import { BaseLayout } from "src/app/helpers/base-layout";
import { ClusterDetailsComponent } from "./cluster-details/cluster-details.component";
import { ClusterBroadcastersComponent } from "./cluster-broadcasters/cluster-broadcasters.component";
import { ClusterRedirectionRulesComponent } from "./cluster-redirection-rules/cluster-redirection-rules.component";
import { ZxObjectTrackingMainListComponent } from "src/app/components/shared/zx-object-tracking/main-list/zx-object-tracking-main-list.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import { Cluster } from "../cluster";
import { Broadcaster, Tag } from "src/app/models/shared";

export abstract class ClusterLayouts extends BaseLayout {
    abstract cluster: Cluster;
    abstract eventsObjects: {
        broadcaster_cluster?: Cluster;
        broadcaster?: Broadcaster[];
    };
    abstract isVip(): boolean;
    abstract canEdit(cluster: Cluster): boolean;
    abstract scalingAccount: { id: number; name: string; type: string };
    abstract resourceTags: Tag[];
    abstract refreshClusterPromise(): Promise<Cluster>;

    keys = {
        primaryDetails: [
            //The order is important for the layout
            "DNS_PREFIX",
            "PRIORITY",
            "ALERTING_PROFILE",
            "TAGS"
        ],
        secondaryDetails: [
            //The order is important for the layout
            "ALTERNATIVE_CLUSTER",
            "AUTHORIZATION_MODE",
            "SCALING_ACCOUNT",
            "REGION",
            "INSTANCE_TYPE",
            "BROADCASTER_VERSION",
            "LOG_SCTE_35",
            "API_CREDENTIALS",
            "ACTIVATION_KEY_LINK",
            "AUTHORIZATION_PASSWORD",
            "ACCOUNT_NAME",
            "ELASTIC_IPS",
            "RTMP_SERVER",
            "ZONES",
            "KEY_PAIR",
            "SSH_KEY",
            "VPC",
            "SUBNET",
            "SECURITY_GROUP",
            "GPU_TYPE",
            "TERMINATION_PROTECTION",
            "ENHANCED_MONITORING"
        ],
        nonDeletedSecondaryDetails: [
            "ALTERNATIVE_CLUSTER",
            "AUTHORIZATION_MODE",
            "SCALING_ACCOUNT",
            "REGION",
            "INSTANCE_TYPE",
            "BROADCASTER_VERSION",
            "LOG_SCTE_35",
            "API_CREDENTIALS",
            "ACTIVATION_KEY_LINK"
        ],
        widgets: [
            //The order is important for the layout
            "BROADCASTERS",
            "REDIRECTION_RULES",
            "DETAILS",
            "EVENTS",
            "CHANGES",
            "NOTES"
        ]
    } as const;

    constants = Constants;

    protected translatedNames = {
        ...this.translateList(
            [...this.keys.primaryDetails, ...this.keys.secondaryDetails, ...this.keys.widgets],
            this.translate
        ),
        CLUSTER_TAGS: this.translate.instant("CLUSTER_TAGS")
    };

    protected primaryDetailsDefaultLayout = this.keys.primaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false
    })) satisfies DetailsLayout<typeof this.keys.primaryDetails>[];

    protected clusterPrimaryDetails: Details[] = this.primaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected secondaryDetailsDefaultLayout = this.keys.secondaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false,
        isDeleted: !this.keys.nonDeletedSecondaryDetails.includes(key as any)
    })) satisfies DetailsLayout<typeof this.keys.secondaryDetails>[];

    protected clusterSecondaryDetails: Details[] = this.secondaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected widgetHeadersDefaultLayout = this.keys.widgets.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isSelected: true,
        isHidden: false
    })) satisfies WidgetHeaderLayout<typeof this.keys.widgets>[];

    widgetHeaders = this.widgetHeadersDefaultLayout.map(layout => ({
        ...layout,
        fa_icon: this.getIconLayoutKey(layout.key)
    })) satisfies WidgetHeader<typeof this.keys.widgets>[];

    protected widgetsDefaultLayout = this.keys.widgets.reduce((obj, key, index) => {
        obj[key] = {
            title: this.translatedNames[key],
            id: this.translatedNames[key],
            key,
            index: index,
            ...this.constants.defaultWidgetLayout
        };
        return obj;
    }, {} as Record<(typeof this.keys.widgets)[number], WidgetLayout>) satisfies Record<
        (typeof this.keys.widgets)[number],
        WidgetLayout
    >;

    protected clusterWidgets: Widget[] = [
        {
            ...this.widgetsDefaultLayout.EVENTS,
            component: ZxEventsComponent,
            inputs: {
                objects: () => this.eventsObjects,
                id: () => this.cluster.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.DETAILS,
            component: ClusterDetailsComponent,
            inputs: {
                cluster: () => this.cluster,
                canEdit: () => this.canEdit(this.cluster),
                isVip: () => this.isVip(),
                scalingAccount: () => this.scalingAccount
            }
        },
        {
            ...this.widgetsDefaultLayout.BROADCASTERS,
            component: ClusterBroadcastersComponent,
            inputs: {
                cluster: () => this.cluster,
                canEdit: () => this.canEdit(this.cluster),
                resourceTags: () => this.resourceTags,
                bordered: () => true,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.REDIRECTION_RULES,
            component: ClusterRedirectionRulesComponent,
            inputs: {
                cluster: () => this.cluster,
                canEdit: () => this.canEdit(this.cluster)
            }
        },
        {
            ...this.widgetsDefaultLayout.CHANGES,
            component: ZxObjectTrackingMainListComponent,
            inputs: {
                objectId: () => this.cluster.id,
                objectName: () => this.cluster.name,
                objectType: () => "broadcaster_cluster",
                objectApiType: () => "broadcaster_clusters",
                autoRows: () => false,
                refreshObjectDataFunction: () => this.refreshClusterPromise.bind(this)
            }
        },
        {
            ...this.widgetsDefaultLayout.NOTES,
            component: ZxAdvancedNotesComponent,
            inputs: {
                model: () => this.cluster,
                type: () => "broadcaster_cluster",
                id: () => this.cluster.id,
                canEdit: () => this.canEdit(this.cluster)
            }
        }
    ];

    private getIconLayoutKey(key: (typeof this.keys.widgets)[number]): string | string[] {
        switch (key) {
            case "DETAILS":
                return "align-justify";
            case "BROADCASTERS":
                return "server";
            case "EVENTS":
                return "exclamation-triangle";
            case "CHANGES":
                return "clock-rotate-left";
            case "REDIRECTION_RULES":
                return "tasks";
            case "NOTES":
                return ["far", "sticky-note"];
            default:
                throw new Error("Unknown title"); //TODO should it throw error or return empty string?
        }
    }
}
